<template>
    <div class=container>
        <div class=table>
<ul>
<li>  <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample01.zip">1-Felt <br>    </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample02.zip">2-Polyester <br>     </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample03.zip">3-Terrycloth <br>    </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample04.zip">4-Rough Plastic <br>     </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample05.zip">5-Leather <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample06.zip">6-Sandpaper <br>    </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample07.zip">7-Velvet <br>    </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample08.zip">8-Pebbles<br> </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample09.zip">9-Frosted Glass<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample10.zip">10-Plaster_a<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample11.zip">11-Plaster_b<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample12.zip">12-Rough Paper<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample13.zip">13-Artificial Grass<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample14.zip">14-Roof Shingle<br>  </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample15.zip">15-Aluminum Foil<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample16.zip">16-Cork <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample17.zip">17-Rough Tile<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample18.zip">18-Rug_a<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample19.zip">19-Rug_b<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample20.zip">20-Styrofoam<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample21.zip">21-Sponge<br>   </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample22.zip">22-Lambswool<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample23.zip">23-Lettuce Leaf<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample24.zip">24-Rabbit Fur <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample25.zip">25-Quarry Tile<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample26.zip">26-Loofa<br>    </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample27.zip">27-Insulation <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample28.zip">28-Crumpled Paper <br>  </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample29.zip">29-(2 zoomed)<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample30.zip">30-(11 zoomed) <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample31.zip">31-(12 zoomed) <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample32.zip">32-(14 zoomed) <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample33.zip">33-Slate_a <br>    </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample34.zip">34-Slate_b <br>    </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample35.zip">35-Painted Spheres <br> </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample36.zip">36-Limestone <br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample37.zip">37-Brick_a<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample38.zip">38-Ribbed Paper<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample39.zip">39-Human Skin<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample40.zip">40-Straw<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample41.zip">41-Brick_b<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample42.zip">42-Corduroy<br>  </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample43.zip">43-Salt Crystals<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample44.zip">44-Linen<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample45.zip">45-Concrete_a<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample46.zip">46-Cotton<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample47.zip">47-Stones<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample48.zip">48-Brown Bread<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample49.zip">49-Concrete_b<br>  </a>

</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample50.zip">50-Concrete_c<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample51.zip">51-Corn Husk<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample52.zip">52-White Bread<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample53.zip">53-Soleirolia Plant<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample54.zip">54-Wood_a<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample55.zip">55-Orange Peel<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample56.zip">56-Wood_b<br>  </a>

     
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample57.zip">57-Peacock Feather<br>   </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample58.zip">58-Tree Bark<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample59.zip">59-Cracker_a<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample60.zip">60-Cracker_b<br>  </a>
</li><li> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample61.zip">61-Moss<br>  </a> 
 

</li></ul>
<hr>
<router-link to="/repository/CURET"> [Return to Database Home Page] </router-link>
</div>

</div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>